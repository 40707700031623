/* Base Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #1e1e2f;
  color: #fff;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.logo-container img {
  max-height: 50px;
}

.menu-button {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
}

/* Navigation Styles */
.header-nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-link {
  color: #c9d1d9;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-link:hover,
.nav-link.active {
  background: #323b50;
  color: #61dafb;
}

.logout-button {
  background: #ff4d4f;
  border: none;
  color: #fff;
  padding: 0.5rem 1.2rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.logout-button:hover {
  background: #ff6b6d;
}

.scan-icon {
  color: #61dafb;
  font-size: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.scan-icon:hover {
  color: #c9d1d9;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .header-nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #1e1e2f;
    padding: 1rem;
    gap: 1rem;
  }

  .header-nav.open {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align menu items */
  }

  .nav-link,
  .logout-button {
    width: 100%;
    text-align: center;
    padding: 0.6rem; /* Ensure consistent padding for all items */
  }
}

/* Tablet Styles (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .menu-button {
    display: block; /* Ensure menu button is visible on tablets */
  }

  .header-nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #1e1e2f;
    padding: 1.5rem;
    gap: 1rem;
  }

  .header-nav.open {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
  }

  .nav-link {
    padding: 0.8rem 1rem; /* Increase padding for better tap area */
    font-size: 1rem; /* Adjust font size for tablets */
  }

  .logout-button {
    padding: 0.8rem 1rem;
    font-size: 1rem;
    text-align: center;
    width: 100%; /* Ensure logout button spans full width */
  }

  .scan-icon {
    margin-right: 0;
    font-size: 1.2rem;
  }
}
