/* HomePage Styles */
.homepage {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Hero Section */
.hero-section {
  background: url("../img/gpt5.png") no-repeat center center/cover;
  min-height: 80vh;
  position: relative;
}

.hero-overlay {
  background: rgba(0, 0, 0, 0.6);
  min-height: 80vh;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lead {
  color: #fff;
} 

.para-success {
  color: #fff;
}

.hero-overlay h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-overlay p {
  font-size: 1.25rem;
}

/* Arrow Down Icon */
.arrow-down {
  color: #fff;
}

/* Marketing Section */
.marketing-section {
  background-color: #343a40;
  padding: 50px 20px;
}

.marketing-section h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  color: #f8f9fa;
}

/* Why Register with Us Section */
h3.text-success {
  font-weight: 600;
  margin-top: 20px;
}

/* Card Styles */
.benefit-card {
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Benefits Section */
.bg-light {
  padding: 50px 0;
}

/* Download App Section */
.download-app-section {
  background-color: #f8f9fa;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.app-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.app-icon {
  max-width: 160px;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.app-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.logo {
  max-width: 330px;
  width: 100%;
  height: auto;
}

.contact-details {
  z-index: 1;
}

/* Media Queries for Responsiveness */
@media (max-width: 992px) {
  .hero-overlay h1 {
    font-size: 2.2rem;
  }

  .marketing-section h2 {
    font-size: 2.2rem;
  }

  .hero-overlay p {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .hero-overlay h1 {
    font-size: 2rem;
  }

  .marketing-section h2 {
    font-size: 1.8rem;
  }

  .hero-overlay p {
    font-size: 1rem;
  }

  .app-icon {
    max-width: 130px;
  }

  .logo {
    max-width: 250px;
  }

  .contact-details {
    position: static !important;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .hero-overlay h1 {
    font-size: 1.8rem;
  }

  .marketing-section h2 {
    font-size: 1.6rem;
  }

  .hero-overlay {
    padding: 40px 15px;
  }

  .btn-lg {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .logo {
    max-width: 200px;
  }
  /* Slideshow Container */
/* ... (previous styles remain unchanged) ... */

/* Carousel Styles */
/* ... (previous styles remain unchanged) ... */

/* Carousel Styles */
/* Slideshow Container */
/* Outer White Background Container */
.slideshow-container {
  width: 300px;
  height: 300px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Inner Mini Container */
.mini-slideshow-wrapper {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Slideshow Images */
.slide-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

}