.merged-gym-page {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.gym-details-col .card, 
.gallery-col .card {
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gym-details-col .card:hover, 
.gallery-col .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.gallery-item {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 69, 58, 0.8);
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: rgba(255, 69, 58, 1);
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease;
}

.upload-button:hover {
  background: linear-gradient(135deg, #5a0cb3 0%, #2065e0 100%);
}

.upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.no-images {
  text-align: center;
  font-size: 1.5rem;
  color: #888;
  margin-top: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .gallery-item {
    width: 100px;
    height: 100px;
  }

  .delete-button {
    width: 20px;
    height: 20px;
    font-size: 0.8rem;
  }

  .upload-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
