.bank-account-page {
  font-family: 'Roboto', sans-serif;
}

.bank-account-page h2 {
  margin: 0;
}

.bank-account-page .btn-light {
  font-weight: 600;
  font-size: 0.9rem;
}

.bank-account-page .card {
  border: none;
  border-radius: 8px;
  overflow: hidden;
}

.bank-account-page .card-header {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}

.bank-account-page .card-body {
  background-color: #f9f9f9;
}

.bank-account-page .modal-body {
  padding: 1.5rem;
}

.bank-account-page .modal-footer {
  justify-content: space-between;
}

.bank-account-page .btn-primary,
.bank-account-page .btn-success {
  min-width: 120px;
  font-size: 0.9rem;
}

.bank-account-page .form-control {
  border-radius: 6px;
  margin-top: 0.5rem;
}
