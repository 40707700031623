/* General styles for Booking Page */
.gym-display-container {
    margin-top: 80px; /* Reduced from 120px */
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
}

.card-header {
    margin-top: 20px; /* Reduced from 50px */
    padding-top: 30px; /* Added to move text down */
    padding-bottom: 30px; /* Added for balance */
}

/* Chart container */
.chart-container {
    height: 300px; /* Fixed height for the chart */
    margin-bottom: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
    .gym-display-container {
        margin-top: 60px; /* Further reduced for mobile */
        padding: 15px;
    }

    .card {
        font-size: 0.9rem;
    }

    .card-header {
        margin-top: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .form-label {
        font-size: 0.9rem;
    }

    .form-control,
    .form-select {
        font-size: 0.9rem;
    }

    /* Table adjustments */
    .table-responsive {
        overflow-x: auto;
    }

    .table {
        width: 100%;
        font-size: 0.8rem;
    }

    th, td {
        padding: 0.5rem;
        white-space: nowrap;
    }

    /* Buttons and links */
    .btn {
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
        width: 100%;
        margin: 5px 0;
    }

    /* Summary widget */
    .summary-widget {
        font-size: 0.9rem;
    }

    .summary-widget .badge {
        font-size: 1rem;
    }

    /* Chart adjustments */
    .chart-container {
        height: 200px; /* Reduced height for mobile */
    }
}

/* Additional responsive adjustments */
@media (max-width: 576px) {
    .gym-display-container {
        margin-top: 60px;
        padding: 10px;
    }

    .card-header h2 {
        font-size: 1.5rem;
    }

    .summary-widget .col-md-4 {
        margin-bottom: 15px;
    }

    .btn {
        width: 100%;
        margin-bottom: 10px;
    }
}

