/* General Styling */
.slot-container {
    padding: 20px;
    background: linear-gradient(120deg, #f3f4f7, #ffffff);
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 30px; /* Added extra margin to push it down */
    margin-top: 60px; /* Added space above the heading */
    color: #444;
    text-align: center;
    font-weight: 600;
}

/* Slot Card Table */
.slot-card {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    overflow-x: auto; /* Allows horizontal scrolling on small screens */
}

.slot-table {
    width: 100%; /* Full width for smaller screens */
    font-size: 0.9rem; /* Slightly smaller font */
    border-collapse: collapse; /* Keep borders collapsed */
    border: 1px solid #e0e0e0; /* Add border around the entire table */
}

.slot-table th,
.slot-table td {
    padding: 8px 10px; /* Reduce padding to make rows more compact */
    font-size: 0.85rem; /* Reduce font size for better fit */
    border: 1px solid #e0e0e0; /* Keep borders on each cell */
}

.slot-table th {
    font-size: 0.9rem; /* Slightly larger font for headers */
    text-align: left;
    background-color: #007bff; /* Background color for header */
    color: #fff; /* White text color for header */
}

.slot-table tr:hover {
    background: #f7faff; /* Light background on hover */
}

.slot-container {
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    max-height: 80vh; /* Limit table height to 80% of the viewport height */
    border: 1px solid #e0e0e0; /* Border around the slot container */
}

/* Add Slot Form */
.add-slot-form {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-size: 1rem;
}

.add-slot-header {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.form-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px 0;
    justify-content: space-between; /* Ensures alignment on larger screens */
    flex-direction: row; /* Aligns inputs in a row */
}

select,
input {
    flex: 1 0 30%; /* Ensures equal width for inputs and dropdown */
    
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;
    height: 40px; /* Ensures consistent height */
}

.add-button {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.add-button:hover {
    background: #218838;
}
button {
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

/* Common Button Style */
.back-button,
.next-button,
.edit-button,
.cancel-button,
.delete-buttons,
.delete-buttons-enable {
    background-color: #007bff; /* Default blue color */
    color: white;
}

/* Hover Effects */
.back-button:hover,
.next-button:hover,
.edit-button:hover,
.cancel-button:hover,
.delete-buttons:hover,
.delete-buttons-enable:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Special Styling for Add Button */
.add-button {
    background: #28a745; /* Unique green color */
    color: white;
}

.add-button:hover {
    background: #218838; /* Darker green on hover */
}

/* Disabled Buttons (Optional Styling) */
button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Additional Button Alignments (for layout) */
.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.back-button,
.next-button,
.edit-button,
.cancel-button,
.delete-buttons,
.delete-buttons-enable {
    flex: 1; /* Ensures all buttons have the same width */
    text-align: center;
}
/* Specific styling for Disable and Edit buttons */
.delete-buttons,
.delete-buttons-enable,
.edit-button {
    background-color: #007bff; /* Default blue color */
    color: white;
    border: none;
    padding: 8px 12px; /* Reduced padding for smaller size */
    border-radius: 8px;
    font-size: 0.85rem; /* Slightly smaller font */
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
    width: auto; /* Ensure they don't stretch */
}

/* Hover effects for Disable and Edit buttons */
.delete-buttons:hover,
.delete-buttons-enable:hover,
.edit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.delete-buttons {
    background-color: #cf7c1d; /* Default blue color */
    color: white;
}

/* Align buttons next to each other */
.action-buttons {
    display: flex;
    gap: 10px; /* Add spacing between buttons */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .slot-container {
        padding: 10px;
        gap: 15px;
    }
    .slot-table {
        width: 90%; /* Slightly reduce table width for better fit */
        margin: 0 auto; /* Center the table horizontally */
    }
    .slot-table .edit-button,
    .slot-table .delete-buttons,
    .slot-table .delete-buttons-enable {
        font-size: 0.8rem; /* Further reduce font size */
        padding: 4px 8px; /* Further reduce padding */
    }

    .action-buttons {
        flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
        gap: 5px; /* Reduce the gap between buttons */
    }
    .slot-table th,
    .slot-table td {
        padding: 6px 8px; /* Further reduce padding */
    }

    .slot-card {
        padding: 10px; /* Reduce overall padding */
    }

    h1 {
        font-size: 1.8rem; /* Adjust header size for smaller screens */
        margin-bottom: 15px; /* Add spacing for visibility */
    }

    .add-slot-form {
        padding: 10px; /* Reduce form padding */
    }
    h1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .form-fields {
        flex-wrap: wrap; /* Allows wrapping on smaller screens */
        flex-direction: row; /* Keeps inputs in a row */
        gap: 10px;
        justify-content: center; /* Centers the inputs */
    }

    select,
    input {
        flex: 1 0 45%; /* Adjusted width for smaller screens */
        max-width: 100%; /* Full width on smaller screens */
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    .form-fields {
        flex-direction: column; /* Stacks inputs vertically on very small screens */
        gap: 10px;
    }

    select,
    input {
        width: 100%; /* Full width for inputs and dropdowns */
        max-width: 100%;
    }
}

.active-status {
    display: inline-block;
    background-color: #28a745; /* Green color for active */
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.inactive-status {
    display: inline-block;
    background-color: #dc3545; /* Red color for inactive */
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.active-status:hover,
.inactive-status:hover {
    filter: brightness(1.1); /* Slightly brighten on hover */
    cursor: default;
}


.add-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(90deg, #ff5f6d, #ffc371);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.add-button:hover {
    transform: scale(1.05);
    background: linear-gradient(90deg, #ffc371, #ff5f6d);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.add-button:active {
    transform: scale(0.95);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.add-button .fa-icon {
    margin-right: 8px;
    font-size: 20px;
}

/* Specific colors for disable and enable buttons */
.disable-all-button {
    background: linear-gradient(90deg, #f76b1c, #ffaf7b);
}

.disable-all-button:hover {
    background: linear-gradient(90deg, #ffaf7b, #f76b1c);
}

.enable-all-button {
    background: linear-gradient(90deg, #6fcf97, #66d9e8);
}

.enable-all-button:hover {
    background: linear-gradient(90deg, #66d9e8, #6fcf97);
}

